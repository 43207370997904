@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import 'variables.scss';
@import 'breakpoints.scss';
@import 'modal.scss';
@import 'customMixins.scss';


body {
  letter-spacing: 0.5px !important;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a.nav-link {
    font-weight: 500;
  }

  h1,
  h2 {
    font-family: $h1-h2-font-family
  }

  h3,
  h4 {
    font-weight: 600;
  }

  p {
    line-height: 1.2;
    font-family: $small-texts-font;
    font-weight: 400;
  }

  .btn,
  button {
    letter-spacing: 0.5px !important;
  }

  .btn-link {
    color: $steel-blue;
    text-decoration: none;
    padding: 0;
    @include hover-pale-lavande();

    &.delete {
      color: $red;
      font-weight: 600;
      font-size: 0.9rem;
      @include hover-pale-lavande();
    }
  }

  .black {
    color: $black;
  }

  i {
    line-height: 0;
  }

  .btn.btn-primary {
    background-color: $steel-blue;
    border: 0;
    min-width: 15rem;

    &:hover,
    &:active,
    &:focus {
      background-color: $pale-lavende;
    }
  }



  .btn-outline-primary {
    @include btn-outline();
    min-width: 15rem;

  }

  .btn-outline-primary {
    &.pale-lavande {
      @include btn-outline-pale-lavande();
      min-width: 15rem;
    }
  }

  .btn-outline-secondary {
    @include btn-outline();
    padding: 0.25rem 1rem;
  }

  .navbar {
    height: $navbar-height;
    background: $light-grey;
  }

  font-size: $font-size-base*0.9 !important;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base;
  }

  .main-logo {
    max-width: 150px;

    @include media-breakpoint-up(sm) {
      max-width: 180px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 200px;
    }
  }

  .nav-item {
    a {
      color: $steel-blue;
    }
  }

  .main-container {
    background: $light-grey;
    color: $black;

    @include media-breakpoint-up(sm) {
      .content {
        min-height: 600px;
      }
    }

    @include media-breakpoint-up(xl) {
      .content {
        min-height: 700px;
      }
    }
  }

  .upload-file-section {
    //background: linear-gradient($light-grey, $white);
    background: $light-grey;

    .dash {
      min-height: 200px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='#{$border-radius2}' ry='#{$border-radius2}' stroke='%23#{str-slice(#{$steel-blue}, 2)}ff' stroke-width='4' stroke-dasharray='9%2c 19%2c 19%2c 13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: $border-radius2;
      position: relative;

      .cloud {
        font-size: 6rem;

        &.grey {
          color: $light-grey;
        }

        &.red {
          color: $red;
        }

        &.orange {
          color: $orange;
        }

        &.yellow {
          color: $yellow;
        }

        &.green {
          color: $green;
        }
      }
    }
  }

  .bad-good-pics {
    width: 120px;
    word-wrap: break-word;

    p {
      font-size: $p-font-size !important;
      margin-bottom: 0;
    }

    .pet-img {
      width: $good-bad-img-with/1.25;
      height:$good-bad-img-with/1.25;
    }
  }

  .thumbnail {
    @include thumbnail-frame();

    .pet-img {
      width: $thumbnail-img-with;
      height: $thumbnail-img-with;
      display: block;
      object-fit: cover;
      border-radius: $img-border-radius;
    }
  }

  button.btn-icon {
    background: none;
    border: 0;
    width: $btn-icon-width-height;
    position: relative;
    background-repeat: no-repeat;
    background-size: $btn-icon-width-height $btn-icon-width-height;
    height: $btn-icon-width-height;

    &.delete {
      background-image: url(../public/assets/svg/steel-blue-bin.svg);

      &:hover,
      &:active,
      &:focus {
        background-image: url(../public/assets/svg/pale-lavende-bin.svg);
      }
    }

    &.edit {
      background-image: url(../public/assets/svg/edit-button-svgrepo-com.svg);

      &:hover,
      &:active,
      &:focus {
        background-image: url(../public/assets/svg/edit-button-svgrepo-com.svg);
      }
    }

    &.like {
      background-image: url(../public/assets/svg/like.svg);

      &:hover,
      &:active,
      &:focus {
        background-image: url(../public/assets/svg/pale-lavande-like.svg);
      }
    }

    &.share {
      background-image: url(../public/assets/svg/share.svg);

      &:hover,
      &:active,
      &:focus {
        background-image: url(../public/assets/svg/pale-lavande-share.svg);
      }
    }

    &.download {
      background-image: url(../public/assets/svg/download.svg);

      &:hover,
      &:active,
      &:focus {
        background-image: url(../public/assets/svg/pale-lavande-download.svg);
      }
    }
  }

  #PlansAccordion {
    .card {
      height: 100%;

      a.btn {
        position: absolute;
        bottom: 30px;
        margin: 0 auto;
      }

      .footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }


  .konvajs-content {
    height: 400px !important;
  }

  .step-2,
  .step-3,
  .step-4,
  .step-5,
  .step-6,
  .waitingComponent {
    min-height: calc(100vh - #{$navbar-height} - #{$bottom-bar-height});
  }

  .waitingComponent {
    display: flex;
    flex-direction: column;

    .waiting-text {
      flex: 1;
    }
  }

  .code {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      width: 100%;
      height: 4rem;
      font-size: 3rem;
    }
  }

  .FIE_root {
    font-family: $font-family;

    .FIE_topbar {
      display: flex;
      justify-content: center;

      button {
        @include btn-outline();
      }
    }
  }

  .mt-6 {
    margin-top: 6rem;
  }

  .actions {
    img {
      width: 1.5rem;
    }
  }

  .bottom-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0.75rem;
    z-index: 1;
    background: linear-gradient(transparent, $white 5%);
    height: $bottom-bar-height;

    .btn-primary {
      animation: ballonEffect 3s forwards;
    }
  }

  @keyframes ballonEffect {
    0% {
      transform: scale(0);
      opacity: 0;

    }

    50% {
      transform: scale(1.1);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .italic {
    font-style: italic;
  }

  .carousel {

    .carousel-indicators {
      bottom: -60px;
    }

    .carousel-control-prev {
      left: -65px;
    }

    .carousel-control-next {
      right: -65px;
    }

    .carousel-inner {
      position: static;
      max-width: $base-img-width;
    }

    .carousel-item {
      position: static;

      .pet-img {
        width: 100%;
        height: auto;
        max-width: $base-img-width;
        max-height: $base-img-width;
      }

      .carousel-caption {
        bottom: -170px;
      }
    }
  }

  .loader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid $pale-lavende;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: spinner 1s linear infinite;
  }


  .carousel-container {
    opacity: 0;
    animation-name: fade;
    animation-duration: 2s;
    animation-delay: 24s;
    animation-fill-mode: forwards;
  }

  .delay-11 {
    opacity: 0;
    animation-name: fade;
    animation-duration: 2s;
    animation-delay: 11s;
    animation-fill-mode: forwards;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fade {
    from {
      display: flex;
      visibility: hidden;
    }

    to {
      visibility: visible;
      opacity: 1;
    }
  }
}

.container-progress-bar {
  border-radius: $border-radius2;
  background: $light-grey;
  //box-shadow: inset 0 1px 2px #00000040, 0 1px #ffffff14;
}

.progress-bar-inner {
  height: 18px;
  border-radius: $border-radius2;
  background-image:
    linear-gradient(to bottom, #ffffff4d, #ffffff0d);
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.carousel-thumbnail {
  opacity: 0.4;

  img {
    cursor: pointer;
  }

  .active & {
    opacity: 1;
    @include thumbnail-frame();
    padding: 4px;
  }
}

.progress-bar-inner.red {
  background-color: $red;
}

.progress-bar-inner.orange {
  background-color: $orange;
}

.progress-bar-inner.yellow {
  background-color: $yellow;
}

.progress-bar-inner.green {
  background-color: $green;
}

.oval {
  position: relative;

  circle {
    stroke: $steel-blue;
  }

  svg {
    stroke: $steel-blue;
  }
}

.custom-oval {
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);

  circle {
    stroke: $white;
  }

  svg {
    stroke: $white;
  }

  &.every-img {
    top: -13px;
    right: -13px;
    left: auto;
    transform: none;

    circle {
      stroke: $grey;
    }

    svg {
      stroke: $green;
    }

    &.loaded {
      circle {
        stroke: $green;
        stroke-opacity: 1;
      }

      svg {
        stroke: transparent;
      }
    }
  }
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.check-icon {
  font-size: 1.5rem;
}

.gallery {
  background: $white;
}

.white-bg {
  background-color: $white;
}

.light-grey-bg {
  background-color: $light-grey;
}

.how-it-works-icon {
  font-size: 8rem;
  position: relative;
  display: inline-block;
}

.how-it-works-icon.one i {

  background: linear-gradient(to right, $vivid-turquoise, $steel-blue);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.how-it-works-icon.two i {
  background: linear-gradient(to right, $pale-lavende 30%, $vivid-turquoise);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.how-it-works-icon.three i {
  background: linear-gradient(to right, $steel-blue, $pale-lavende 90%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.timer {
  span {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    color: $steel-blue;
    font-size: $h3-font-size;
  }
}

.mw-70 {
  max-width: 70%;
}

.alert-width {
  max-width: 600px !important;
}

.alert {
  z-index: 9999;
}

input {
  font-family: $small-texts-font;
  font-weight: 200;
}

input::placeholder {
  color: $grey !important;
  font-weight: 200;
  font-style: italic;
}

.join {
  background: $light-grey;
}

.overlay-block {
  padding: 2px;
  width: 100%;
  height: 100%;
  
  .content {
    backdrop-filter: blur(2px);
    background-color: rgba($color: $black2, $alpha: 0.6);
    border-radius: $border-radius2;
    border: 2px solid transparent;
    z-index: 999999;
    color: $white;

    i {
      font-size: 6rem;
    }
  }
}

.next-continue {
  z-index: 1;
  position: relative;
}

.hide-overlay {
  animation: hide-overlay  1.5s forwards;
}

.have-account {
  margin-bottom: 2.5rem;
}

@keyframes hide-overlay  {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
  
}
.fineprint{
  font-size: 10pt;
  color: #aaaaaa;
}