.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.pagination li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
}

.pagination  li.previous a,
.pagination  li.next a,
.pagination li.break a {
    border-color: transparent;
}

.pagination li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
}

.pagination li.disabled a {
    color: grey;
}

.pagination li.disable,
.pagination li.disabled a {
    cursor: default;
}
.accordion-item{
    background-color: transparent;
}
.accordion-body{
    background-color: transparent;
}
.thumbsup-icon {
    display:block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    background: #0dcaf0;
    color: white;
    font-size: 16pt;

}
.thumbsup-icon[disabled] {
    background: gray;
}

.yolo-thumbnail > img {
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 640px;
}

.yolo-thumbnail > canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.yolo-thumbnail {
    margin-top: 5px;
    position: relative;
}
.sm-p > p {
    font-size: 8pt;
    margin: 1Px;
}
.sm-p > h4 {
    font-size: 8pt;
    margin: 1Px;
}
.alert{
    z-index: 999;
}