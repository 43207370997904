@import 'variables.scss';

.modal {
  backdrop-filter: blur(7px);

  &.signup-modal {
    background-color: rgba($black, 0.5);
  }

  &-dialog {
    border-radius: $border-radius;
    background-color: $white;
  }

  &-content {
    background-color: $light-grey !important;
    border-radius: $border-radius !important;
    border: 0 !important;
  }

  &-header,
  &-footer,
  &-body {
    border: none !important;
    background-color: transparent !important;
  }


  &.inferest-result {
    .modal-header {
      display: none;
    }
    .modal-dialog {
      max-width: 600px;
      .modal-body {
        padding: 3rem;
        .carousel-control-prev {
          left: -50px;
        }
        .carousel-control-next {
          right: -50px;
        }
      }
    }
  }
}