$font-family: ultima-pro-regular, 'Open Sans', sans-serif !important;
$h1-h2-font-family: ultima-pro-black-regular, 'Open Sans', sans-serif !important;
$small-texts-font: Roboto !important;
$h1-font-size: 2.2rem;
$h2-font-size: 1.4rem;
$h3-font-size: 1.2rem;
$h4-font-size: 0.8rem;
$p-font-size: 0.9rem;
$btn-font-size: 1rem;

$border-radius: .375rem;
$img-border-radius: .1875rem;
$border-radius2: 30px;
$navbar-height: 70px;
$bottom-bar-height: 110px;

$thumbnail-img-with: 256px;
$good-bad-img-with: 120px;
$base-img-width: 512px;
$thumbnail-padding: 8px;

$btn-icon-width-height: 22px;


//Colors
$grey: #a9abae;
$light-grey: rgba($grey, 0.2); 
$white: #fff;
$vivid-turquoise: #03fcd4;
$pale-lavende: #cfade1;
$steel-blue: #59a6c6;
$black: #333333;
$black2: #000;
$yellow: #FFDF00;
$orange: #FF8C00;
$green: #28c728;
$red: #c91919;
