@import 'variables.scss';

@mixin thumbnail-frame() {
  box-shadow: 0px 4px 4px 4px rgba($black, 0.25);
  background-color: $white;
  border-radius: $border-radius;
  padding: $thumbnail-padding;
}

@mixin btn-outline() {
  background-color: transparent;
  border-color: $steel-blue;
  color: $steel-blue;

  &:hover,
  &:active,
  &:focus {
    border-color: $pale-lavende !important;
    color: $pale-lavende !important;
    background-color: transparent !important;
  }
}

@mixin btn-outline-pale-lavande() {
  background-color: transparent;
  border-color: $pale-lavende;
  color: $pale-lavende;

  &:hover,
  &:active,
  &:focus {
    border-color: $steel-blue !important;
    color: $steel-blue !important;
    background-color: transparent !important;
  }
}

@mixin hover-pale-lavande() {

  &:hover,
  &:active,
  &:focus {
    color: $pale-lavende;
  }
}