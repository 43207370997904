@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import 'variables.scss';

body {
  h1 {
    font-size: $h1-font-size;
  }

  h2 {
    font-size: $h2-font-size;
  }

  h3 {
    font-size: $h3-font-size;
  }

  h4 {
    font-size: $h4-font-size;
  }

  p,a {
    font-size: $p-font-size;
  }

  @include media-breakpoint-up(sm) {
    h1 {
      font-size: $h1-font-size*1.2;
    }

    h2 {
      font-size: $h2-font-size*1.2;
    }

    h3 {
      font-size: $h3-font-size*1.2;
    }

    h4 {
      font-size: $h4-font-size*1.2;
    }

    p,a {
      font-size: $p-font-size*1.1;
    }

    .btn-primary, .btn-outline-primary {
      font-size: $btn-font-size;
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: $h1-font-size*1.4;
    }

    h2 {
      font-size: $h2-font-size*1.4;
    }

    h3 {
      font-size: $h3-font-size*1.4;
    }

    h4 {
      font-size: $h4-font-size*1.4;
    }

    p,a {
      font-size: $p-font-size*1.2;
    }

    .btn-primary, .btn-outline-primary {
      font-size: $btn-font-size*1.1;
    }
  }

  @include media-breakpoint-up(xl) {
    h1 {
      font-size:  $h1-font-size*1.6;
    }

    h2 {
      font-size:  $h2-font-size*1.6;
    }

    h3 {
      font-size: $h3-font-size*1.6;
    }

    h4 {
      font-size: $h4-font-size*1.6;
    }

    a {
      font-size: $p-font-size*1.3;
    }

    .btn-primary, .btn-outline-primary {
      font-size: $btn-font-size*1.2;
    }
  }
}